.headerCentral {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.highlight {
  background-color: #ffe58f; /* A soft yellow highlight */
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
}

.tableWrapper {
  margin: 20px;
  padding: 20px;
  background-color: #f0f2f5; /* Light grey background */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.loadingSpinner {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
